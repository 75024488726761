import React, { Fragment } from "react";
import MenuCM from "../components/others/MenuCM";
import Logo from "../images/casamayor.png";

const Menu = ({ history }) => (
  <Fragment>
    <MenuCM name="menu" />
    <div className="contenedor fondo-quinielas">
      <button onClick={() => history.goBack()} className="boton-regresar">
        <i className="fas fa-chevron-left" />
      </button>
      <div className="container">
        <h1 className="text-center">Menú</h1>
      </div>
      <div className="text-center mt-5 container">
        <img className="img-soccer" src={Logo} alt="Soccer" />
      </div>
      <div className="container mt-5">
        <div className="d-flex flex-row w-100">
          <div className="w-50">
            <button
              onClick={() => history.push("/casamayor")}
              className="boton-cm"
            >
              <i className="fas fa-crown"></i>
              <p>Casa Mayor</p>
            </button>
          </div>
          <div className="w-50">
            <button
              onClick={() => history.push("/reglas")}
              className="boton-cm"
            >
              <i className="fas fa-question-circle"></i>
              <p>Reglas del juego</p>
            </button>
          </div>
        </div>
        <div className="text-center w-100">
          <button onClick={() => history.push("/como")} className="boton-cm">
            <i className="fas fa-gamepad"></i>
            <p>¿Cómo jugar?</p>
          </button>
        </div>
        <div className="mt-5">
          <h5 className="text-center">Síguenos en nuestras redes sociales</h5>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <div className="m-3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send/?phone=5215586688702&text=Hola!!+Me+gustaría+jugar+en+la+Quiniela+de+Casa+Mayor+⚽💸🏆&app_absent=0"
            >
              <i className="fab fa-whatsapp text-second icono-cm" />
            </a>
          </div>
          <div className="m-3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/casamayormx"
            >
              <i className="fab fa-instagram text-second icono-cm"></i>
            </a>
          </div>
          <div className="m-3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/casamayormx"
            >
              <i className="fab fa-facebook text-second icono-cm" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default Menu;
