import React, {Fragment, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Request from '../../async/request';

const Clave = ({show, handleClose, usuario}) =>{
    const [loading, setLoading] = useState(false);
    const [nueva, setNueva] = useState("");

    useEffect(
        () =>{
            setLoading( false );
            setNueva("");
        },[usuario]
    )

    const cambiar = async () =>{
        let body = { whatsapp: usuario.whatsapp };
        let request = new Request("POST", body, 1, "/service/password/cambiar");
        let response = await request.exec();
        setLoading( true );
        if( response.isSuccess )
            setNueva( response.data );
        else
            setNueva("Error");
    }

    return(
        <Fragment>
            <Modal show={show} onHide={handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    {
                        !loading ?
                            <div>
                                <h3 className="text-center mt-4 mb-3">Confirmar operación</h3>
                            </div>
                            :
                            <div>
                                <h3 className="text-center mt-4 mb-3">Listo</h3>
                            </div>
                    }
                    <div className="d-flex flex-column justify-content-center">
                        {
                            !loading ?
                            <>
                                    <h5 className="text-center mb-4">¿Estás seguro que deseas cambiar la clave de {usuario.usuario}?</h5>
                                    <div className="text-center">
                                        <button onClick={cambiar} className="btn-alternative-cm m-3">Si</button>
                                        <button onClick={handleClose} className="btn-alternative-cm m-3">No</button>
                                    </div>
                                </>
                                :
                                void(0)
                        }
                        {nueva === "" ? void(0):
                            nueva === "Error" ?
                            <div>
                                <h5>No se pudo generar una nueva clave. Inténtelo más tarde.</h5>
                            </div>
                            :
                            <div>
                                <h5>La nueva clave de {usuario.usuario} es: {nueva}</h5>
                                <div className="text-center">
                                    <button onClick={handleClose} className="btn-alternative-cm m-3">Cerrar</button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default Clave;