import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import MenuCM from "../components/others/MenuCM";
import logo from "../images/casamayor.png";

const Casa = ({ regresar }) => (
  <Fragment>
    <MenuCM name="menu" />
    <div className="contenedor p-3 fondo-quinielas">
      <button onClick={regresar} className="boton-regresar">
        <i className="fas fa-chevron-left" />
      </button>
      <div className="text-center pt-2">
        <img src={logo} alt="cuentas" className="img-logo" />
        <h2>Casa Mayor</h2>
      </div>
      <div className="container text-medium mb-5 mt-4">
        <p className="text-center">
          Casa Mayor es la clásica Quiniela de barrios y mercados con
          participantes de varias partes del país, hoy a tu alcance con un
          click.
        </p>

        <h5 className="text-center mb-5">¡Diviértete!</h5>
        <div className="text-center mb-5">
          <Link to="/jugar" className="boton-cm">
            Jugar
          </Link>
        </div>
      </div>
    </div>
  </Fragment>
);

export default Casa;
