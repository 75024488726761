import html2canvas from "html2canvas";
import { CardMatchFlayer } from "../components/cards/CardMatchFlayer";
import Footer from "../images/footer.jpg";
import Header from "../images/header.jpg";

export const Layout = ({ juegos, teams, jornada }) => {
  const onExport = async () => {
    const doc = document.getElementById("layout");
    const val = await html2canvas(doc, {
      useCORS: true,
      allowTaint: true,
      onrendered: (canvas) => {
        const data = canvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.download = "layout.png";
        link.href = data;
        link.click();
      },
    });
    const data = val.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = "layout.png";
    link.href = data;
    link.click();
  };

  return (
    <>
      <div className="v-center bg-linear">
        <div id="layout" className="flayer-container">
          <div>
            <img className="flayer-cover" src={Header} alt="header" />
          </div>
          <h1 className="mt-3 text-center title-flayer title-flayer-size">
            {jornada ? jornada.jornada : ""}
          </h1>
          <div className="flayer-container-body">
            <div className="w-full">
              {juegos.map((item, i) => (
                <CardMatchFlayer key={i} item={item} teams={teams} />
              ))}
            </div>
          </div>
          <div>
            <img className="flayer-cover" src={Footer} alt="footer" />
          </div>
        </div>
      </div>
      <div className="button-export">
        <button className="btn btn-primary" onClick={onExport}>
          Guardar
        </button>
      </div>
    </>
  );
};
