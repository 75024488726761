import React, { useEffect, useState } from "react";
import Logo from "../others/Logo";

export const CardMatchFlayer = ({ teams, item }) => {
  const [info, setInfo] = useState([{}, {}]);

  useEffect(() => {
    let array = [{}, {}];
    teams.forEach((element) => {
      if (element.clave === item.local) array[0] = element;
      else if (element.clave === item.visita) array[1] = element;
    });
    setInfo(array);
  }, [item, teams]);

  return (
    <div className="card-match-flayer">
      <div className="name-team-container text-r">
        <p className="name-team-flayer">{info[0].nombre}</p>
      </div>
      <div className="flayer-local">
        <Logo team={info[0]} />
      </div>
      <div className="d-flex flex-row flayer-options">
        <div>L</div>
        <div>E</div>
        <div>V</div>
      </div>
      <div className="flayer-visita">
        <Logo team={info[1]} />
      </div>
      <div className="name-team-container">
        <p className="name-team-flayer ps-2">{info[1].nombre}</p>
      </div>
    </div>
  );
};
