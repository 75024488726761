import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";
import CardMatchJornada from "../components/cards/CardMatchJornada";
import CardQuinielaAdmin from "../components/cards/CardQuinielaAdmin";
import Information from "../components/modals/Information";
import NuevoEquipo from "../components/modals/NuevoEquipo";
import NuevoUsuario from "../components/modals/NuevoUsuario";

const Jornada = ({
  crearUser,
  agregarUsuario,
  cancelarUsuario,
  form,
  onChange,
  teams,
  juego,
  juegos,
  selectJuego,
  agregar,
  crear,
  cargando,
  modal,
  handleClose,
  eliminar,
  create,
  onCloseCreate,
  onCreate,
  ligas,
  cambiar,
  update,
  actualizar,
  jornadas,
  cargarJornada,
  eliminarJornada,
  logout,
  type,
  users,
  eliminarUsuario,
}) => (
  <Fragment>
    <div className="principal">
      <NuevoUsuario show={crearUser} handleClose={cancelarUsuario} />
      <Information
        show={modal.show}
        handleClose={handleClose}
        title={modal.title}
        body={modal.body}
      />
      <NuevoEquipo ligas={ligas} show={create} handleClose={onCloseCreate} />
      <div className="container pt-5">
        <div className="d-flex justify-content-end">
          <button
            onClick={() => window.open("layout", "_blank")}
            className="boton-cm me-4"
          >
            Ver layout
          </button>
          <button
            onClick={() => window.open("transmision", "_blank")}
            className="boton-cm me-4"
          >
            Ver layout de transmision
          </button>
          <button
            onClick={() => window.open("resultados", "_blank")}
            className="boton-cm me-4"
          >
            Ver layout de resultados
          </button>
          <button onClick={logout} className="boton-cm">
            Salir
          </button>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="mt-5 mb-4">
              <h3 className="text-center color-second">
                Información de la jornada
              </h3>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="jornada">
                    Nombre
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={form.jornada}
                    id="jornada"
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="clave">
                    Clave
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={form.clave}
                    id="clave"
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="inicio">
                    Inicio
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={form.inicio}
                    id="inicio"
                    className="form-control"
                    type="datetime-local"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="fin">
                    Cierre
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={form.fin}
                    id="fin"
                    className="form-control"
                    type="datetime-local"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 mb-4">
              <h3 className="text-center color-second">Partidos</h3>
            </div>
            <div className="text-end">
              <button className="boton-cm" onClick={onCreate}>
                Agregar equipo
              </button>
            </div>
            <div className="mt-4 row justify-content-center">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="liga">
                    Ligas
                  </label>
                  <select
                    required
                    onChange={selectJuego}
                    value={juego.liga}
                    id="liga"
                    className="form-select"
                  >
                    <option value="">Seleccionar</option>
                    {ligas.map((item, i) => (
                      <option key={i}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="local">
                    Local
                  </label>
                  <select
                    required
                    onChange={selectJuego}
                    value={juego.local}
                    id="local"
                    className="form-select"
                  >
                    <option value="">Seleccionar</option>
                    {teams.map(
                      (team, i) =>
                        (team.liga === juego.liga) | (juego.liga === "") && (
                          <option key={i} value={team.clave}>
                            {team.nombre}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="visita">
                    Visita
                  </label>
                  <select
                    required
                    onChange={selectJuego}
                    value={juego.visita}
                    id="visita"
                    className="form-select"
                  >
                    <option value="">Seleccionar</option>
                    {teams.map(
                      (team, i) =>
                        (team.liga === juego.liga) | (juego.liga === "") && (
                          <option key={i} value={team.clave}>
                            {team.nombre}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-4 row justify-content-center">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="liga">
                    Fecha
                  </label>
                  <input
                    value={juego.fecha}
                    onChange={selectJuego}
                    type="datetime-local"
                    id="fecha"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="l-control mb-2" htmlFor="local">
                    Transmisión
                  </label>
                  <select
                    value={juego.transmision}
                    onChange={selectJuego}
                    required
                    id="transmision"
                    className="form-select"
                  >
                    <option value="">Seleccionar</option>
                    <option value="PRIME">Amazon Prime</option>
                    <option value="APPLE">Apple TV</option>
                    <option value="Azteca">Azteca</option>
                    <option value="Canal 5">Canal 5</option>
                    <option value="Claro Video">Claro Video</option>
                    <option value="Disney">Disney +</option>
                    <option value="ESPN">ESPN</option>
                    <option value="Fox">Fox</option>
                    <option value="Fox Premium">Fox Premium</option>
                    <option value="HBO">HBO</option>
                    <option value="Las Estrellas">Las Estrellas</option>
                    <option value="Paramount">Paramount</option>
                    <option value="Sky">Sky</option>
                    <option value="TUDN">TUDN</option>
                    <option value="Vix">Vix</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="text-center mt-4">
              {update ? (
                <button onClick={actualizar} className="boton-cm">
                  Actualizar
                </button>
              ) : (
                <button onClick={agregar} className="boton-cm">
                  Agregar
                </button>
              )}
            </div>
            <div className="row justify-content-center mt-4 mb-5">
              {juegos.map((item, i) => (
                <div key={i} className="col-md-4 mt-3">
                  <div className="d-flex justify-content-center">
                    <div onClick={() => eliminar(item)} className="item-number">
                      <span className="jnumber">{i + 1}</span>
                      <span className="dmatch">X</span>
                    </div>
                    <CardMatchJornada
                      cambiar={() => cambiar(item, i)}
                      teams={teams}
                      local={item.local}
                      fecha={item.fecha}
                      transmision={item.transmision}
                      visita={item.visita}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-4">
              {cargando ? (
                <Spinner animation="grow" />
              ) : (
                <button onClick={crear} className="boton-cm">
                  Crear jornada
                </button>
              )}
            </div>
          </div>
          <div className="col-md-3 pt-5">
            <h3 className="text-center">Jornadas</h3>
            <div className="contenedor-jornadas">
              {jornadas.length > 0 ? (
                jornadas.map((jornada, i) => (
                  <CardQuinielaAdmin
                    eliminarQuiniela={eliminarJornada}
                    jornada={jornada}
                    key={i}
                    verJornada={() => cargarJornada(jornada)}
                  />
                ))
              ) : (
                <h5 className="text-center mt-5">
                  No hay Quinielas disponibles por ahora
                </h5>
              )}
            </div>
            {type.usuario === "A" ? (
              <>
                <h3 className="mt-3 text-center">Administradores</h3>
                <div className="text-center">
                  <button onClick={agregarUsuario} className="boton-cm">
                    Agregar
                  </button>
                </div>
                <div className="contenedor-jornadas mt-2 mb-4">
                  {users.map((user, i) => (
                    <div
                      key={i}
                      className="card-quiniela d-flex flex-row justify-content-around mt-3"
                    >
                      <div>
                        <span>
                          <i className="fab fa-whatsapp" /> {user.whatsapp}
                        </span>
                        <p className="m-0">
                          {user.usuario === "A" ? "Admin" : "Editor"}
                        </p>
                      </div>
                      <button
                        onClick={() => eliminarUsuario(user.whatsapp)}
                        className="h-50 boton-cm"
                      >
                        Eliminar
                      </button>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              void 0
            )}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default Jornada;
