import { useEffect, useState } from "react";
import Request from "../async/request";
import { Layout } from "../pages/Layout";

export const LayoutContainer = () => {
  const [juegos, setJuegos] = useState([]);
  const [jornada, setJornada] = useState(null);
  const [jornadas, setJornadas] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fn = async () => {
      const request = new Request("GET", null, 0, "/control/jornada");
      const response = await request.exec();
      if (response.isSuccess) {
        setJornadas(response.data);
      }
    };
    fn();
  }, []);

  const onSelect = async (jornada) => {
    setJornada(jornada);
    const matches = new Request(
      "GET",
      null,
      0,
      "/control/partido/clave/" + jornada.clave
    );
    const responseMatch = await matches.exec();
    if (responseMatch.isSuccess) {
      setJuegos(responseMatch.data);
      const requestTeams = new Request("GET", null, 0, "/control/equipo");
      const responseTeams = await requestTeams.exec();
      setTeams(responseTeams.data);
    }
  };

  return (
    <>
      {jornada === null && (
        <div className="row justify-content-center">
          {jornadas.map((item, i) => (
            <div key={i} className="col-4 px-4 py-4">
              <div className="row color-1">
                <div className="col-8">
                  <p className="descripcion-quiniela m-0 p-0">{item.jornada}</p>
                  <p className="descripcion-quiniela m-0 p-0">{item.clave}</p>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => onSelect(item)}
                  >
                    Ver layout
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {jornada !== null && (
        <Layout teams={teams} juegos={juegos} jornada={jornada} />
      )}
    </>
  );
};
