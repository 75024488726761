import React from 'react';
import { Link } from 'react-router-dom';
import MenuCM from '../components/others/MenuCM';
import Redes from '../components/others/Redes';
import CM from '../images/logo.png';
import Logo from '../images/casamayor.png';

const Inicio = () =>(
    <div className="contenedor contenedor-centrado fondo-quinielas">
        <Redes />
        <MenuCM name="inicio" />
        <img src={Logo} className="imagen-logo rotating" alt="Logo Casa Mayor" />
        <img src={CM} className="imagen-logo" alt="Letras Casa Mayor" />
        <Link to="/jugar" className="boton-cm mt-4">Jugar</Link>
    </div>
);

export default Inicio;