import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";
import CardMatch from "../components/cards/CardMatch";
import Information from "../components/modals/Information";
import Costo from "../components/others/Costo";
import MenuCM from "../components/others/MenuCM";

const Juego = ({
  juegos,
  jornada,
  regresar,
  juego,
  onChange,
  agregar,
  aleatorio,
  limpiar,
  body,
  title,
  show,
  onClose,
  quinielas,
  borrar,
  enviar,
  cargando,
  name,
  onName,
  teams,
}) => (
  <Fragment>
    <MenuCM name="juego" />
    <div className="contenedor fondo-resultados">
      <Information
        show={show}
        title={title}
        body={body}
        handleClose={onClose}
      />
      <button onClick={regresar} className="boton-regresar">
        <i className="fas fa-chevron-left" />
      </button>
      <h3 className="text-center title-flayer pt-4">{jornada}</h3>
      <div className="contenedor-juego">
        <form id="form-match" className="container-match">
          {juegos.map((item, i) => (
            <CardMatch
              teams={teams}
              onChange={onChange}
              key={i}
              item={item}
              id={item.orden}
            />
          ))}
        </form>
      </div>
      <div className="text-center py-4">
        <p className="name-team text-game">{juego}</p>
      </div>
      <div></div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <button
              onClick={aleatorio}
              type="button"
              className="options btn-alternative-cm"
            >
              <i className="fas fa-dice"></i>
              <span className="p-3">Tirar a la suerte</span>
            </button>
          </div>
          <div className="col-6">
            <button
              type="button"
              onClick={limpiar}
              className="options btn-alternative-cm"
            >
              <i className="fas fa-backspace"></i>
              <span className="p-3">Limpiar</span>
            </button>
          </div>
        </div>
        <div className="text-center mt-3">
          <button
            onClick={agregar}
            type="button"
            className="w-100 options btn-alternative-cm"
          >
            <i className="fas fa-plus"></i>
            <span className="p-3">Añadir Quiniela</span>
          </button>
        </div>
      </div>

      {quinielas.length > 0 ? (
        <Fragment>
          <div className="text-center pb-4 pt-4 name-team">
            <h3>Quinielas a ingresar</h3>
            <p className="text-game mb-2">Total: {quinielas.length}</p>
            <Costo quinielas={quinielas.length} />

            <div className="">
              {quinielas.map((game, i) => (
                <div
                  key={i}
                  className="mt-2 d-flex flex-row justify-content-center align-items-center"
                >
                  <p className="text-game">{game}</p>
                  <i
                    onClick={borrar}
                    id={game}
                    className="icon-button fas fa-trash-alt"
                  ></i>
                </div>
              ))}
            </div>
            <div className="form-group m-4">
              <label htmlFor="name" className="name-team mb-2">
                Nombre:
              </label>
              <input
                onChange={onName}
                value={name}
                type="text"
                autoComplete="off"
                required
                id="name"
                className="text-input form-control"
              ></input>
            </div>
            {cargando ? (
              <Spinner className="mt-3" animation="grow" />
            ) : (
              <div className="text-center mt-3">
                <button onClick={enviar} className="btn-alternative-cm">
                  Enviar
                </button>
              </div>
            )}
          </div>
        </Fragment>
      ) : (
        void 0
      )}
    </div>
  </Fragment>
);

export default Juego;
