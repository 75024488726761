import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Versus from "../../images/versus.png";
import Logo from "../others/Logo";

const CardMatchJornada = ({
  cambiar,
  local,
  visita,
  teams = [],
  transmision,
  fecha,
  flag = true,
}) => {
  const [info, setInfo] = useState([{}, {}]);

  useEffect(() => {
    let array = [{}, {}];
    teams.forEach((element) => {
      if (element.clave === local) array[0] = element;
      else if (element.clave === visita) array[1] = element;
    });
    setInfo(array);
  }, [local, visita, teams]);

  return (
    <div>
      <div onClick={cambiar} className="card-match">
        <div className="team">
          <Logo team={info[0]} />
          <p className="name-team">{info[0].nombre}</p>
        </div>
        <div className="team flex flex-col">
          <img className="icon-versus mb-1" src={Versus} alt="Partidos" />
        </div>
        <div className="team">
          <Logo team={info[1]} />
          <p className="name-team">{info[1].nombre}</p>
        </div>
      </div>
      {flag && (
        <>
          <div className="text-center">
            <span>{dayjs(fecha).format("dddd DD MMM HH:mm")}</span>
          </div>
          <div className="text-center">
            <span>{transmision}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default CardMatchJornada;
