import React from "react";
import HOST from "../../host";

const LogoRes = ({ team }) => (
  <div className="logo-con">
    <img
      src={HOST + `/logos/${team}.png`}
      alt="versus"
      className="icon-versus"
    />
  </div>
);

export default LogoRes;
