import React, { Fragment } from "react";
import MenuCM from "../components/others/MenuCM";
import logo from "../images/casamayor.png";

const Afiliados = ({ regresar }) => (
  <Fragment>
    <MenuCM name="menu" />
    <div className="contenedor p-3 fondo-quinielas">
      <button onClick={regresar} className="boton-regresar">
        <i className="fas fa-chevron-left" />
      </button>
      <div className="text-center pt-2">
        <img src={logo} alt="cuentas" className="img-logo" />
        <h2>Descuentos al mayoreo para afiliados</h2>
      </div>
      <div className="container mb-5 mt-4">
        <ul>
          <li>
            <h4>15% de descuento a partir de 20 Quinielas</h4>
          </li>
          <li>
            <h4 className="mt-3">20% de descuento a partir de 100 Quinielas</h4>
          </li>
        </ul>
      </div>
      <div className="text-center">
        <a
          className="boton-cm"
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send/?phone=5215586688702&text=Hola!!+Me+gustaría+jugar+en+la+Quiniela+de+Casa+Mayor+⚽💸🏆&app_absent=0"
        >
          Afiliarme
        </a>
      </div>
    </div>
  </Fragment>
);

export default Afiliados;
