import React, { useEffect, useState } from 'react';
import Request from '../async/request';
import Usuario from '../pages/Usuario';

const UsuarioContainer = () =>{
    const [usuarios, setUsuarios] = useState([]);
    const [modal, setModal] = useState(false);
    const [usuario, setUsuario] = useState({});

    useEffect(
        () => {
            async function get(){
                let request = new Request("GET", null, 1, "/control/usuario");
                let response = await request.exec();
                if( response.isSuccess )
                    setUsuarios( response.data );
            }
            get();
        },[]
    );

    const onCambiarClave = (usuario) =>{
        setUsuario( usuario);
        setModal( true );
    }

    const ocultar = () => setModal( false );

    const bloquear = async ({target}) =>{
        let body = { whatsapp: target.name };
        let request = new Request("POST", body, 1, "/control/usuario/bloqueo" );
        let response = await request.exec();
        if( response.isSuccess ){
            request = new Request("GET", null, 1, "/control/usuario");
            response = await request.exec();
            if (response.isSuccess)
                setUsuarios( response.data );
        }
    }

    return(
        <Usuario
            onCambiarClave = { onCambiarClave }
            usuario = { usuario }
            usuarios = { usuarios } 
            ocultar = { ocultar }
            modal = { modal }
            bloquear = { bloquear }
        />
    )
};

export default UsuarioContainer;