import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import CardMatchJornada from "../cards/CardMatchJornada";

const Jornada = ({ show, handleClose, title, body, partidos, teams }) => (
  <Fragment>
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <h3 className="text-center mt-4 mb-3">{title}</h3>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <h5 className="text-center mb-4">{body}</h5>
          {partidos.map((partido, i) => (
            <CardMatchJornada
              flag={false}
              teams={teams}
              key={i}
              cambiar={() => void 0}
              local={partido.local}
              visita={partido.visita}
            />
          ))}
          <div className="text-center">
            <button onClick={handleClose} className="btn-alternative-cm">
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </Fragment>
);

export default Jornada;
