import React, { useEffect, useState } from "react";

const Costo = ({ quinielas }) => {
  const [costo, setCosto] = useState();

  useEffect(() => {
    if (quinielas < 10) setCosto(30 * quinielas);
    else if (quinielas >= 50) setCosto(24 * quinielas);
    else if (quinielas >= 20) setCosto(25 * quinielas);
    else if (quinielas >= 10) setCosto(30 * (quinielas - 1));
  }, [costo, quinielas]);

  return (
    <>
      <p className="text-game mb-2 name-team">Costo: {costo} MXN</p>
    </>
  );
};

export default Costo;
