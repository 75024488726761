import React from "react";
import HOST from "../../host";

const Logo = ({ team }) => {
  if (team) {
    if (team.color1 === null)
      return (
        <div className="logo-con">
          <img
            src={HOST + `/logos/${team.clave}.png`}
            alt="versus"
            className="icon-versus"
          />
        </div>
      );
    else
      return (
        <div className="d-flex flag-container">
          <div className="color1" style={{ backgroundColor: team.color1 }} />
          <div className="color2" style={{ backgroundColor: team.color2 }} />
        </div>
      );
  } else return void 0;
};

export default Logo;
