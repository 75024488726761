import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AfiliadosContainer from "../containers/AfiliadosContainer";
import CasaContainer from "../containers/CasaContainer";
import ComoContainer from "../containers/ComoContainer";
import ComparteContainer from "../containers/ComparteContainer";
import InicioContainer from "../containers/InicioContainer";
import JornadaContainer from "../containers/JornadaContainer";
import JuegoContainer from "../containers/JuegoContainer";
import { LayoutContainer } from "../containers/LayoutContainer";
import LoginContainer from "../containers/LoginContainer";
import MenuContainer from "../containers/MenuContainer";
import PagoContainer from "../containers/PagoContainer";
import PreguntaContainer from "../containers/PreguntaContainer";
import PromocionContainer from "../containers/PromocionContainer";
import ProximaContainer from "../containers/ProximaContainer";
import { ResultadoContainer } from "../containers/ResultadoContainer";
import { TransmisionContainer } from "../containers/TransmisionContainer";
import UsuarioContainer from "../containers/UsuarioContainer";

const App = () => {
  return (
    <BrowserRouter>
      <Route exact path="/jornada">
        <JornadaContainer />
      </Route>
      <Route exact path="/admin">
        <LoginContainer />
      </Route>
      <Route exact path="/usuario">
        <UsuarioContainer />
      </Route>
      <Route exact path="/layout">
        <LayoutContainer />
      </Route>
      <Route exact path="/transmision">
        <TransmisionContainer />
      </Route>
      <Route exact path="/resultados">
        <ResultadoContainer />
      </Route>
      <div className="contenedor-padre">
        <div className="contenedor-hijo">
          <Switch>
            <Route exact path="/">
              <InicioContainer />
            </Route>
            <Route exact path="/proxima">
              <ProximaContainer />
            </Route>
            <Route exact path="/menu">
              <MenuContainer />
            </Route>
            <Route exact path="/jugar">
              <JuegoContainer />
            </Route>
            <Route exact path="/pago">
              <PagoContainer />
            </Route>
            <Route exact path="/comparte">
              <ComparteContainer />
            </Route>
            <Route exact path="/promocion">
              <PromocionContainer />
            </Route>
            <Route exact path="/afiliados">
              <AfiliadosContainer />
            </Route>
            <Route exact path="/como">
              <ComoContainer />
            </Route>
            <Route exact path="/casamayor">
              <CasaContainer />
            </Route>
            <Route exact path="/reglas">
              <PreguntaContainer />
            </Route>
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
