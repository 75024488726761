import React, { Fragment } from "react";
import MenuCM from "../components/others/MenuCM";
import reglas from "../images/reglas.jpg";

const Pregunta = ({ regresar }) => (
  <Fragment>
    <MenuCM name="menu" />
    <div className="contenedor p-3 fondo-quinielas">
      <button onClick={regresar} className="boton-regresar">
        <i className="fas fa-chevron-left" />
      </button>
      <div className="text-center pt-4 pb-5">
        <img src={reglas} alt="cuentas" className="img-fluid mb-4 rounded-5" />
      </div>
    </div>
  </Fragment>
);

export default Pregunta;
