import React from 'react';
import { useHistory } from 'react-router';
import Menu from '../pages/Menu';

const MenuContainer = () =>{
    const history = useHistory();

    return(
        <Menu history={history} />
    )
};

export default MenuContainer;