import React from 'react';
import { useHistory } from 'react-router';
import Pregunta from '../pages/Pregunta';

const PreguntaContainer = () =>{
    const history = useHistory();

    return(
        <Pregunta 
            regresar = { () => history.goBack() }
        />
    )
};

export default PreguntaContainer;