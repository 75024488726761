import { useEffect, useState } from "react";
import Request from "../async/request";
import { Resultado } from "../pages/Resultado";

export const ResultadoContainer = () => {
  const [juegos, setJuegos] = useState([]);
  const [jornada, setJornada] = useState(null);
  const [jornadas, setJornadas] = useState([]);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const fn = async () => {
      const request = new Request("GET", null, 0, "/control/jornada/resultados");
      const response = await request.exec();
      if (response.isSuccess) {
        setJornadas(response.data);
      }
    };
    fn();
  }, []);

  const onChange = (value, index, flag) => {
    let items = juegos;
    if (flag) items[index].rl = value;
    else items[index].rv = value;
    setJuegos([...items]);
  };

  const onSelect = async (jornada) => {
    setJornada(jornada);
    const matches = new Request(
      "GET",
      null,
      0,
      "/control/partido/clave/" + jornada.clave
    );
    const responseMatch = await matches.exec();
    if (responseMatch.isSuccess) {
      setJuegos(responseMatch.data);
    }
  };

  return (
    <>
      {!toggle && (
        <>
          <div className="row justify-content-center">
            {jornadas.map((item, i) => (
              <div key={i} className="col-4 px-4 py-4">
                <div className="row mx-4 color-1">
                  <div className="col-8">
                    <p className="descripcion-quiniela m-0 p-0">
                      {item.jornada}
                    </p>
                    <p className="descripcion-quiniela m-0 p-0">{item.clave}</p>
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => onSelect(item)}
                    >
                      Ver partidos
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="container d-flex justify-content-center">
            <div className="game-result-container">
              {juegos.map((item, i) => (
                <div
                  key={i}
                  className="row py-1 d-flex justify-content-center align-items-center"
                >
                  <div className="col text-center">{item.nombreLocal}</div>
                  <div className="col">
                    <input
                      value={juegos[i].rl}
                      onChange={(e) => onChange(e.target.value, i, true)}
                      className="form-control w-50"
                    />
                  </div>
                  <div className="col text-center">{item.nombreVisita}</div>
                  <div className="col">
                    <input
                      value={juegos[i].rv}
                      onChange={(e) => onChange(e.target.value, i, !true)}
                      className="form-control w-50"
                    />
                  </div>
                </div>
              ))}
              <div className="text-center">
                <button
                  onClick={() => setToggle(true)}
                  className="btn btn-primary"
                >
                  Ver layout
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {toggle && <Resultado juegos={juegos} jornada={jornada} />}
    </>
  );
};
