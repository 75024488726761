import React, {Fragment, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import Request from '../../async/request';

const NuevoUsuario = ({show, handleClose}) =>{
    const [form, setForm] = useState({
        whatsapp:'',
        clave:'',
        correo:'-',
        usuario:'',
    });

    const onChange = ({target}) =>{ 
        setForm({...form, [target.id]: target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let body = { usuario: {...form}};
        let request = new Request("POST", body, 1, "/control/usuario");
        let response = await request.exec();
        let icon = response.isSuccess ? 'success' : 'error';
        let title = response.isSuccess ? 'Usuario creado' : 'Error al crear el usuario';
        setForm({
            whatsapp:'',
            clave:'',
            correo:'-',
            usuario:'',
        });
        Swal.fire({
            toast: true,
            icon: icon,
            title: title,
            position: 'top',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true
        });
    }

    return(
        <Fragment>
            <Modal show={show} onHide={handleClose} backdrop="static"  aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="nombre">Whatsapp</label>
                                    <input required id="whatsapp" value={form.whatsapp} onChange={onChange} className="form-control" type="text" />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="clave">Clave</label>
                                    <input value={form.clave} onChange={onChange} required id="clave" className="form-control" type="text" />
                                </div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="usuario">Tipo</label>
                                    <select onChange={onChange} value={form.usuario} required id="usuario" className="form-select">
                                        <option value="">Seleccionar</option>
                                        <option value="A">Administrador</option>
                                        <option value="E">Editor</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="text-end mt-4">                            
                            <button type="button" className="boton-cm m-2" onClick={handleClose}>Cancelar</button>
                            <button className="boton-cm">Crear usuario</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
} 

export default NuevoUsuario;