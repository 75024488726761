import React, { useEffect, useState } from "react";
import Logo from "../others/Logo";

const CardMatch = ({ teams, id, item, onChange }) => {
  const [info, setInfo] = useState([{}, {}]);

  useEffect(() => {
    let array = [{}, {}];
    teams.forEach((element) => {
      if (element.clave === item.local) array[0] = element;
      else if (element.clave === item.visita) array[1] = element;
    });
    setInfo(array);
  }, [item, teams]);

  return (
    <div className="card-match">
      <div className="mar-t team">
        <Logo team={info[0]} />
        <p className="name-team">{info[0].nombre}</p>
      </div>

      <div className="card-options">
        <div className="radio-flex">
          <div className="radio-container">
            <div className="radio-options">
              <input
                className="radio-input"
                id={"L" + id}
                type="radio"
                name={id}
                value="L"
              />
              <label
                onClick={() => onChange("L", id)}
                className="radio-label"
                htmlFor={"L" + id}
              >
                L
              </label>

              <input
                className="radio-input"
                id={"E" + id}
                type="radio"
                name={id}
                value="E"
              />
              <label
                onClick={() => onChange("E", id)}
                className="radio-label"
                htmlFor={"E" + id}
              >
                E
              </label>

              <input
                className="radio-input"
                id={"V" + id}
                type="radio"
                name={id}
                value="V"
              />
              <label
                onClick={() => onChange("V", id)}
                className="radio-label"
                htmlFor={"V" + id}
              >
                V
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="mar-t team">
        <Logo team={info[1]} />
        <p className="name-team">{info[1].nombre}</p>
      </div>
    </div>
  );
};

export default CardMatch;
