import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import Request from "../async/request";
import Jornada from "../pages/Jornada";

const JornadaContainer = () => {
  const [form, setForm] = useState({
    liga: "",
    jornada: "",
    clave: "",
    inicio: "",
    fin: "",
  });

  const [juego, setJuego] = useState({
    liga: "",
    local: "",
    visita: "",
    fecha: "",
    transmision: "",
  });

  const [ligas, setLigas] = useState([]);
  const [modal, setModal] = useState({ show: false, title: "", body: "" });
  const [create, setCreate] = useState(false);
  const [teams, setTeams] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [juegos, setJuegos] = useState([]);
  const [update, setUpdate] = useState(false);
  const [item, setItem] = useState(-1);
  const [jornadas, setJornadas] = useState([]);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState("");
  const history = useHistory();
  const [crearUser, setCrearUser] = useState(false);

  useEffect(() => {
    async function get() {
      let request = new Request("POST", null, 1, "/service/valida");
      let response = await request.exec();
      if (response.isSuccess) {
        request = new Request("GET", null, 1, "/control/usuario");
        response = await request.exec();
        if (response.isSuccess) setUsers(response.data);
        request = new Request("GET", null, 1, "/control/equipo");
        response = await request.exec();
        if (response.isSuccess) setTeams(response.data);
        request = new Request("GET", null, 1, "/control/equipo/ligas");
        response = await request.exec();
        if (response.isSuccess) setLigas(response.data);
        request = new Request("GET", null, 1, "/control/jornada");
        response = await request.exec();
        if (response.isSuccess) setJornadas(response.data);
      }
    }
    const auth = sessionStorage.getItem("auth");
    if (auth) {
      setType(JSON.parse(sessionStorage.getItem("user")));
      get();
    } else history.push("/admin");
  }, [modal, history]);

  useEffect(() => {
    async function get() {
      const request = new Request("GET", null, 1, "/control/equipo");
      const response = await request.exec();
      if (response.isSuccess) setTeams(response.data);
    }
    get();
  }, [create]);

  const onChange = (e) => {
    if (e.target.id !== "liga")
      setForm({ ...form, [e.target.id]: e.target.value });
  };

  const selectJuego = (e) =>
    setJuego({ ...juego, [e.target.id]: e.target.value });

  const agregar = (e) => {
    setJuegos([...juegos, juego]);
    Swal.fire({
      toast: true,
      icon: "success",
      title: "Juego agregado",
      position: "top",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });
    setJuego({ ...juego, local: "", visita: "", transmision: "", fecha: "" });
  };

  const actualizar = (e) => {
    let aux = juegos;
    aux[item] = juego;
    setJuegos(aux);
    Swal.fire({
      toast: true,
      icon: "success",
      title: "Juego actualizado",
      position: "top",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });
    setJuego({ ...juego, local: "", visita: "" });
    setJuego({ ...juego, liga: "" });
    setUpdate(false);
    setItem(-1);
  };

  const eliminar = (elemento) => {
    setJuegos(juegos.filter((item) => item !== elemento));
  };

  const crear = async () => {
    setCargando(true);
    let body = { clave: form.clave };
    let request = new Request("DELETE", body, 1, "/control/jornada");
    let response = await request.exec();
    body = { jornada: { ...form } };
    request = new Request("POST", body, 1, "/control/jornada");
    response = await request.exec();
    if (response.isSuccess) {
      await Promise.all(
        juegos.map(async (game, i) => {
          let body = {
            partido: {
              jornada: form.clave,
              local: game.local,
              visita: game.visita,
              fecha: game.fecha,
              transmision: game.transmision,
              orden: i + 1,
              abierto: 1,
            },
          };
          let request = new Request("POST", body, 1, "/control/partido");
          await request.exec();
        })
      );
      setForm({
        liga: "",
        jornada: "",
        clave: "",
        inicio: "",
        fin: "",
      });
      setJuegos([]);
      setCargando(false);
      setModal({
        show: true,
        title: "Jornada programada",
        body: "La jornada está programada, una vez que se cumpla la fecha estará activa para jugar.",
      });
    }
  };

  const handleClose = () => setModal({ ...modal, show: false });

  const cambiar = (item, i) => {
    setJuego({
      liga: "",
      local: item.local,
      visita: item.visita,
      transmision: item.transmision,
      fecha: item.fecha,
    });
    setUpdate(true);
    setItem(i);
  };

  const cargarJornada = async (jornada) => {
    setJuego({ ...juego, liga: "" });
    setForm({
      liga: jornada.liga,
      jornada: jornada.jornada,
      clave: jornada.clave,
      inicio: String(jornada.inicio).replace(" ", "T"),
      fin: String(jornada.fin).replace(" ", "T"),
    });
    const request = new Request(
      "GET",
      null,
      0,
      "/control/partido/clave/" + jornada.clave
    );
    const response = await request.exec();
    if (response.isSuccess) {
      setJuegos(response.data);
    }
  };

  const eliminarJornada = async (jornada) => {
    Swal.fire({
      title: "¿Seguro que quieres eliminar la Quiniela?",
      text: "Una vez eliminada no podrás editarla posteriormente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let body = { clave: jornada.clave };
        let request = new Request("DELETE", body, 1, "/control/jornada");
        let response = await request.exec();
        request = new Request("GET", null, 0, "/control/jornada");
        response = await request.exec();
        if (response.isSuccess) setJornadas(response.data);
        Swal.fire(
          "Eliminada",
          "La Quiniela se eliminó exitosamente",
          "success"
        );
      }
    });
  };

  const eliminarUsuario = async (numero) => {
    Swal.fire({
      title: `¿Seguro que quieres eliminar el número ${numero}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let body = { whatsapp: numero };
        let request = new Request("DELETE", body, 1, "/control/usuario");
        let response = await request.exec();
        request = new Request("GET", null, 0, "/control/usuario");
        response = await request.exec();
        if (response.isSuccess) setUsers(response.data);
        Swal.fire("Eliminado", "El usuario se eliminó exitosamente", "success");
      }
    });
  };

  const logout = () => {
    sessionStorage.clear();
    history.push("/admin");
  };

  const agregarUsuario = () => setCrearUser(true);
  const cancelarUsuario = async () => {
    setCrearUser(false);
    let request = new Request("GET", null, 1, "/control/usuario");
    let response = await request.exec();
    if (response.isSuccess) setUsers(response.data);
  };

  return (
    <Jornada
      agregarUsuario={agregarUsuario}
      cancelarUsuario={cancelarUsuario}
      crearUser={crearUser}
      form={form}
      onChange={onChange}
      teams={teams}
      juego={juego}
      juegos={juegos}
      selectJuego={selectJuego}
      agregar={agregar}
      crear={crear}
      modal={modal}
      cargando={cargando}
      handleClose={handleClose}
      eliminar={eliminar}
      create={create}
      ligas={ligas}
      onCreate={() => setCreate(true)}
      onCloseCreate={() => setCreate(false)}
      cambiar={cambiar}
      update={update}
      actualizar={actualizar}
      jornadas={jornadas}
      cargarJornada={cargarJornada}
      eliminarJornada={eliminarJornada}
      logout={logout}
      users={users}
      type={type}
      eliminarUsuario={eliminarUsuario}
    />
  );
};

export default JornadaContainer;
